







































import {
  ref,
  defineComponent,
  useContext,
  useRouter,
  useFetch,
  reactive
} from '@nuxtjs/composition-api';
import {  LoginFormFields, ForgotPasswordFormFields, FormName} from '~/modules/customer/components/LoginModal/forms/types';
import { useUser } from '~/modules/customer/composables/useUser';
import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';
import { useContent } from '~/composables';

  export default  defineComponent({
    layout: 'loggedout',
    name: 'BemVindo',
    components: {
      LoginForm: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/forms/LoginForm.vue'),
      ForgotPasswordForm: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/forms/ForgotPasswordForm.vue'),
      ForgotPasswordThankYou: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/forms/ForgotPasswordThankYou.vue')
    },
    setup() {
      const loginForm = ref<LoginFormFields>({
      email: '',
      password: '',
      });
      const forgotPasswordForm = ref<ForgotPasswordFormFields>({
        username: '',
      });
      const currentlyDisplayedForm = ref<FormName>('login');
      const {  $config } = useContext();
      const {
        login,
        loading,
        error: userError,
      } = useUser();
      const isRecaptchaEnabled = false;
      const { request: resetPassword, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();
      const onLoginFormSubmit = async (form: LoginFormFields) => {
        loginForm.value = form;
        await login({ user: { ...form, ...({}) } });
        if (!userError.value.login) {
          router.push('/default')
        }
      };
      const onForgotPasswordFormSubmit = async (form: ForgotPasswordFormFields) => {
        forgotPasswordForm.value = form;
        await resetPassword({ email: form.username, ...({}) });
        currentlyDisplayedForm.value = 'forgotPasswordThankYou';
      };
      const { loadBlocks } = useContent();
      const router = useRouter()
      const blocks = ref([]);
    
      const banner = reactive({
        login: null
      });

      useFetch(async () => {
        blocks.value = await loadBlocks({identifiers: ['banner-login']});
        banner.login = blocks.value[0].content
      })
      
      return {
        userError,
        loading,

        forgotPasswordError,
        forgotPasswordLoading,

        currentlyDisplayedForm,
        // barTitle,

        loginForm,
        forgotPasswordForm,

        isRecaptchaEnabled,
        banner,
        // onRegisterFormSubmit,
        onLoginFormSubmit,
         onForgotPasswordFormSubmit,
      };
    }
  })
